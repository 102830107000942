<template>
  <div class="account">

    <section id="sub_header_box" class="manual manual_pdf">
      <div class="w1300">

        <div class="sub_header_description txt-dark_gray">
          <div class="card_item manual_pdf">
            <h4 class="card_title txt-bold">{{ manualTitle }}</h4>
            <ul class="card_list">
              <li class="small" v-if="manualEditor">{{ manualEditor }}編輯</li>
              <li class="small" v-if="manualReviewer1">{{ manualReviewer1 }}初審</li>
              <li class="small" v-if="manualReviewer2">{{ manualReviewer2 }}複審</li>
            </ul>
          </div>
        </div>

        <img class="heart_bg" src="@/statics/img/index/icon_heart.svg" alt="">

      </div>
    </section>

    <div id="pdf_container" style="background-color: #fff; position: relative">

      <!-- Vue-PDF -->
      <div id="pdfJS">
        <div class="w1300">

          <pdf
            ref="pdfComponent"
            :src="`${API_URL}${manualSrc}`"
            @num-pages="pageCount = $event"
            @page-loaded="currentPage = $event"
          ></pdf>

          <div class="button_box">
            <button
              id="prev"
              class="btn p"
              :class="{ btnDisable: isPrevDisable }"
              @click="prevPage"
            >Previous</button>
            <button
              id="next"
              class="btn p"
              :class="{ btnDisable: isNextDisable }"
              @click="nextPage"
            >Next</button>
            <span>Page: {{ currentPage }} / {{ pageCount }}</span>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import { API_URL } from '@/lib/config';
import { articleView } from '@/lib/http';
import { mapActions } from 'vuex';
import _ from 'lodash';

export default {
  name: 'AccountManualPdf',
  data() {
    return {
      API_URL,
      isPrevDisable: false,
      isNextDisable: false,
      pdfID: '',

      /** 頁數資訊 */
      currentPage: 0,
      pageCount: 0,

      /** 手冊資訊 */
      manualTitle: '',
      manualEditor: '',
      manualReviewer1: '',
      manualReviewer2: '',
      manualSrc: '',
    };
  },
  created() {
    this.pdfID = this.$route.query.id || '';
    if (this.pdfID) {
      articleView({ uuid: this.pdfID }).then((result) => {
        if (result.data.status) {
          const manualInfo = result.data.result.data;
          this.manualTitle = manualInfo.title;
          this.manualEditor = _.get(manualInfo, 'json.editor', '');
          this.manualReviewer1 = _.get(manualInfo, 'json.firstReview', '');
          this.manualReviewer2 = _.get(manualInfo, 'json.secondReview', '');
          this.manualSrc = _.get(manualInfo, 'files.files_0.path', '');
        } else {
          this.openModal({
            message: '無手冊資訊，請返回上一頁重新選擇',
            url: '/account/manual',
          });
        }
      });
    } else {
      this.openModal({
        message: '無手冊資訊，請返回上一頁重新選擇',
        url: '/account/manual',
      });
    }
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    /**
     * 切上一頁
     */
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.$refs.pdfComponent.pdf.loadPage(this.currentPage);
      }
    },
    /**
     * 切下一頁
     */
    nextPage() {
      if (this.currentPage < this.pageCount) {
        this.currentPage += 1;
        this.$refs.pdfComponent.pdf.loadPage(this.currentPage);
      }
    },
  },
  watch: {
    /**
     * 首頁及末頁按鈕disable
     */
    currentPage() {
      this.isPrevDisable = false;
      this.isNextDisable = false;
      if (this.currentPage === 1) {
        this.isPrevDisable = true;
      }
      if (this.currentPage === this.pageCount) {
        this.isNextDisable = true;
      }
    },
  },
  components: {
    pdf,
  },
};
</script>
